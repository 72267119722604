import './viewer.scss';
import {Button, ButtonGroup, Container, DropdownButton, Image, Spinner, Dropdown} from "react-bootstrap";
import {useEffect, useState} from "react";
import {preload_images, Spacer} from "../utils/Spacer";
import {GetAllChapters} from "../utils/mock";
import React from 'react';

const SETTINGS = "viewer-settings";
const MAX_PRELOAD_FORWARD = 5;
const MAX_PRELOAD_BACKWARD = 3;


function loadSettings() {
  let settings = localStorage.getItem(SETTINGS)
  if (!settings) {
    return
  }
  return JSON.parse(settings)
}

function loadViewerState(meta) {
  const id = `${meta.chapter}-${meta.language}`
  let state = localStorage.getItem(id)
  if (!state) {
    return
  }
  return JSON.parse(state)
}

function ChapterSelect({meta}) {
  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    GetAllChapters().then(c => setChapters(c))
  }, [])

  return (
    <div>
      <DropdownButton align="end" title={meta.title}>
        {chapters.map((item, idx) => <Dropdown.Item eventKey={idx} href={`/chapters/${item.language}_${item.volume}_${item.chapter}.html#viewer`}>{item.language} | {item.title}</Dropdown.Item>)}
      </DropdownButton>
    </div>
  )
}

export function Viewer({meta}) {
  const MAX_PAGES = meta.pages.length

  const [settings, setSettings] = useState(loadSettings() || {
    zoom: 100
  });
  const [page, setPage] = useState(loadViewerState(meta) || 0)
  const [preloading, setPreloading] = useState(false)

  const [nextChapterLink, setNextChapterLink] = useState('');
  const [prevChapterLink, setPrevChapterLink] = useState('');


  // Save settings
  useEffect(() => {
    localStorage.setItem(SETTINGS, JSON.stringify(settings))
  }, [settings])

  useEffect(() => {
    const id = `${meta.chapter}-${meta.language}`
    localStorage.setItem(id, JSON.stringify(page))
  }, [page, meta])

  useEffect(() => {
    preload()
    loadNextChapterLinks()
  }, [meta])

  function zoomIn() {
    if (settings.zoom >= 100) {
      return
    }
    setSettings({...settings, zoom: settings.zoom + 10})
  }

  function zoomOut() {
    if (settings.zoom <= 10) {
      return
    }
    setSettings({...settings, zoom: settings.zoom - 10})
  }

  function nextPage() {
    if (page >= MAX_PAGES - 1) {
      return
    }
    setPage(page + 1)
    afterPageChange()
  }


  function prevPage() {
    if (page <= 0) {
      return
    }
    setPage(page - 1)
    afterPageChange()
  }

  function afterPageChange() {
    const a = document.getElementsByClassName("floatingchat-container-wrap")
    if (a.length >= 0) {
      if (!!a[0]) { a[0].className = "hidden" }
    }
    preload()
    // document.getElementById("control-panel").scrollIntoView({block: "end"})
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'smooth'
    });
  }

  const loadNextChapterLinks = () => {
    // Find position in current chapter
    GetAllChapters().then(chapters => {
      console.log('Meta Current Chapter', meta.chapter)
      let current_position = chapters.findIndex(v => v.chapter === meta.chapter)
      console.log('Current Position', current_position)
      
      if (current_position > 0) {
        let prevChapterMeta = chapters[current_position - 1]
        let prevChapterURL = `/chapters/${prevChapterMeta.language}_${prevChapterMeta.volume}_${prevChapterMeta.chapter}.html#viewer`
        setPrevChapterLink(prevChapterURL);
      }

      if (current_position < chapters.length) {
        let nextChapterMeta = chapters[current_position + 1]
        let nextChapterURL = `/chapters/${nextChapterMeta.language}_${nextChapterMeta.volume}_${nextChapterMeta.chapter}.html#viewer`
        setNextChapterLink(nextChapterURL);
      }

    })

  }

  const setKrNovelMode = (value) => {
    setSettings({...settings, krNovelMode: value})
  }

  function preload() {
    let from = page - MAX_PRELOAD_BACKWARD
    from = from < 0 ? 0 : from
    const to = page + MAX_PRELOAD_FORWARD
    const pages = meta.pages.slice(from, to).map(p => p.link)

    setPreloading(true)
    Promise.all(preload_images(pages).map((p) => {
      return new Promise((resolve, reject) => {
        p.then(() => {
          resolve(null)
        }).catch(() => {
          reject()
        })
      })
    })).then(() => {
      setPreloading(false)
    })
  }

  return (
    <div className={"viewer"}>
      <Container>
        {/*<span>{page + 1} / {MAX_PAGES}</span>*/}
        <div id={"control-panel"} className={"control-panel"}>
          <div className={"segment"}>
            {
              prevChapterLink && <Spacer r={".5em"}>
                <a href={prevChapterLink}><Button><i className="bi bi-arrow-left"/><i className="bi bi-arrow-left"/></Button></a>
            </Spacer>
            }
            <Spacer r={".5em"}>
              <Button onClick={prevPage}><i className="bi bi-arrow-left"/></Button>
            </Spacer>
            <ButtonGroup>
              <Button onClick={zoomIn}><i className="bi bi-zoom-in"/></Button>
              <Button onClick={zoomOut}><i className="bi bi-zoom-out"/></Button>
            </ButtonGroup>
            <Spacer l={".5em"}>
                <Button onClick={nextPage}><i className="bi bi-arrow-right"/></Button>
            </Spacer>
            {
              nextChapterLink && <Spacer l={".5em"}>
                <a href={nextChapterLink}><Button><i className="bi bi-arrow-right"/><i className="bi bi-arrow-right"/></Button></a>
            </Spacer>
            }
            <Spacer l={".5em"}>
              <Button onClick={() => setKrNovelMode(!settings.krNovelMode)}><i className="bi bi-file-arrow-down"/></Button>
            </Spacer>
            {
              preloading &&
              <Spacer l={".5em"} r={".5em"} t={".3em"}>
                <Spinner size={"sm"} variant={"light"} animation="border" role="status"/>
              </Spacer>
            }
          </div>
          <div className={"segment"}>
            <DropdownButton align="end" title={`${page + 1} / ${MAX_PAGES}`}>
                {
                  meta.pages.map((_, idx) => {
                    return (<Dropdown.Item onClick={() => {setPage(idx); afterPageChange()}}>
                      {idx + 1}
                    </Dropdown.Item>)
                  })
                }
            </DropdownButton>
            <Spacer r={".5em"}/>
            <ChapterSelect meta={meta}/>
          </div>

        </div>
        {settings.krNovelMode ? 
          <div style={{display: 'flex', flexDirection: 'column', gap: '.5em', alignItems: 'center'}}>
            {meta.pages.map((page) => <Image src={page.link} style={{width: `${settings.zoom}%`}}/>)}
            <div style={{display: 'flex', flexDirection: 'row', gap: '.5em'}}>
              {
                prevChapterLink && <Spacer l={".5em"}>
                  <a href={prevChapterLink}><Button>Previous Chapter</Button></a>
                </Spacer>
              }
              {
                nextChapterLink && <Spacer l={".5em"}>
                  <a href={nextChapterLink}><Button>Next Chapter</Button></a>
                </Spacer>
              }
            </div>
          </div> : 
          <Spacer t={".5em"} b={"1em"}>
            <div className={"image-box"} onClick={nextPage}>
              <Image src={meta.pages[page].link} style={{width: `${settings.zoom}%`}}/>
            </div>
          </Spacer>
        }
      </Container>
    </div>
  )
}