
export async function GetAllChapters() {
  let r = await fetch("/meta.json")
  let d = await r.json()
  // console.log(d)
  return d
}

// export async function _GetAllChapters() {
//   return [
//     {
//       "chapter": "v9jp",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/c892895be3/09-000-0",
//       "lang": "ja",
//       "title": "Volume 9",
//       "volume": "09"
//     },
//     {
//       "chapter": "v8jp",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/fcfc92d1e6/08-000-0",
//       "lang": "ja",
//       "title": "Volume 8",
//       "volume": "08"
//     },
//     {
//       "chapter": "v7jp",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/70f3b6c35d/07-000-0",
//       "lang": "ja",
//       "title": "Volume 7",
//       "volume": "07"
//     },
//     {
//       "chapter": "v6jp",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/78b2a2258f/06-000-0",
//       "lang": "ja",
//       "title": "Volume 6",
//       "volume": "06"
//     },
//     {
//       "chapter": "v5jp",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/ac215d338c/05-000-0",
//       "lang": "ja",
//       "title": "Volume 5",
//       "volume": "05"
//     },
//     {
//       "chapter": "v4jp",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/0adb5e8ee2/04-000-0",
//       "lang": "ja",
//       "title": "Volume 4",
//       "volume": "04"
//     },
//     {
//       "chapter": "v3jp",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/ca93266763/03-000-0",
//       "lang": "ja",
//       "title": "Volume 3",
//       "volume": "03"
//     },
//     {
//       "chapter": "v2jp",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/6d625f464a/02-000-0",
//       "lang": "ja",
//       "title": "Volume 2",
//       "volume": "02"
//     },
//     {
//       "chapter": "v1jp",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/a5748599f0/01-000-0",
//       "lang": "ja",
//       "title": "Volume 1",
//       "volume": "01"
//     },
//     {
//       "chapter": "v0jp",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/a90ce48380/00-000-0",
//       "lang": "ja",
//       "title": "Anthology",
//       "volume": "00"
//     },
//     {
//       "chapter": "058",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/96656e07dc/09-058-0",
//       "lang": "en",
//       "title": "Chapter 058",
//       "volume": "09"
//     },
//     {
//       "chapter": "057",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/bca780e2d6/09-057-0",
//       "lang": "en",
//       "title": "Chapter 057",
//       "volume": "09"
//     },
//     {
//       "chapter": "056",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/44647b6998/09-056-0",
//       "lang": "en",
//       "title": "Chapter 056",
//       "volume": "09"
//     },
//     {
//       "chapter": "055",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/9fa28da704/09-055-0",
//       "lang": "en",
//       "title": "Chapter 055",
//       "volume": "09"
//     },
//     {
//       "chapter": "054",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/dc3d62f7e0/09-054-0",
//       "lang": "en",
//       "title": "Chapter 054",
//       "volume": "09"
//     },
//     {
//       "chapter": "053",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/c81ca27a5d/09-053-0",
//       "lang": "en",
//       "title": "Chapter 053",
//       "volume": "09"
//     },
//     {
//       "chapter": "052",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/a210a09ab1/09-052-0",
//       "lang": "en",
//       "title": "Chapter 052",
//       "volume": "09"
//     },
//     {
//       "chapter": "051x1",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/20ae838f69/08-051x1-0",
//       "lang": "en",
//       "title": "Chapter 051x1",
//       "volume": "08"
//     },
//     {
//       "chapter": "051",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/48a6205e26/09-051-0",
//       "lang": "en",
//       "title": "Chapter 051",
//       "volume": "09"
//     },
//     {
//       "chapter": "050",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/2fb59df1a0/08-050-0",
//       "lang": "en",
//       "title": "Chapter 050",
//       "volume": "08"
//     },
//     {
//       "chapter": "049",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/ad9327c0d9/08-049-0",
//       "lang": "en",
//       "title": "Chapter 049",
//       "volume": "08"
//     },
//     {
//       "chapter": "048",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/e6dd7d58ea/08-048-0",
//       "lang": "en",
//       "title": "Chapter 048",
//       "volume": "08"
//     },
//     {
//       "chapter": "047",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/9adddcb872/07-047-0",
//       "lang": "en",
//       "title": "Chapter 047",
//       "volume": "07"
//     },
//     {
//       "chapter": "046",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/fa42dd7eaa/07-046-0",
//       "lang": "en",
//       "title": "Chapter 046",
//       "volume": "07"
//     },
//     {
//       "chapter": "045",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/08b9870a00/07-045-0",
//       "lang": "en",
//       "title": "Chapter 045",
//       "volume": "07"
//     },
//     {
//       "chapter": "044",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/4c13ba4d44/07-044-0",
//       "lang": "en",
//       "title": "Chapter 044",
//       "volume": "07"
//     },
//     {
//       "chapter": "043",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/997001308d/07-043-0",
//       "lang": "en",
//       "title": "Chapter 043",
//       "volume": "07"
//     },
//     {
//       "chapter": "042x3",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/ceb7eeeb46/06-042x3-0",
//       "lang": "en",
//       "title": "Chapter 042x3",
//       "volume": "06"
//     },
//     {
//       "chapter": "042x1",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/f212ed5548/06-042x1-0",
//       "lang": "en",
//       "title": "Chapter 042x1",
//       "volume": "06"
//     },
//     {
//       "chapter": "042",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/7d94c98c4b/06-042-0",
//       "lang": "en",
//       "title": "Chapter 042",
//       "volume": "06"
//     },
//     {
//       "chapter": "041",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/3b038a2881/06-041-0",
//       "lang": "en",
//       "title": "Chapter 041",
//       "volume": "06"
//     },
//     {
//       "chapter": "040",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/03ed457a29/06-040-0",
//       "lang": "en",
//       "title": "Chapter 040",
//       "volume": "06"
//     },
//     {
//       "chapter": "039",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/8584b498f8/06-039-0",
//       "lang": "en",
//       "title": "Chapter 039",
//       "volume": "06"
//     },
//     {
//       "chapter": "038x1",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/575cd85dcc/06-038x1-0",
//       "lang": "en",
//       "title": "Chapter 038x1",
//       "volume": "06"
//     },
//     {
//       "chapter": "038",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/67793ccd12/05-038-0",
//       "lang": "en",
//       "title": "Chapter 038",
//       "volume": "05"
//     },
//     {
//       "chapter": "037",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/437f1191b0/05-037-0",
//       "lang": "en",
//       "title": "Chapter 037",
//       "volume": "05"
//     },
//     {
//       "chapter": "036",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/24f826de26/05-036-0",
//       "lang": "en",
//       "title": "Chapter 036",
//       "volume": "05"
//     },
//     {
//       "chapter": "035",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/27102ce88d/05-035-0",
//       "lang": "en",
//       "title": "Chapter 035",
//       "volume": "05"
//     },
//     {
//       "chapter": "034",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/4a526092a2/05-034-0",
//       "lang": "en",
//       "title": "Chapter 034",
//       "volume": "05"
//     },
//     {
//       "chapter": "033",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/39be98d80b/05-033-0",
//       "lang": "en",
//       "title": "Chapter 033",
//       "volume": "05"
//     },
//     {
//       "chapter": "032",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/5a456ae232/04-032-0",
//       "lang": "en",
//       "title": "Chapter 032",
//       "volume": "04"
//     },
//     {
//       "chapter": "031",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/3c1ce14fa7/04-031-0",
//       "lang": "en",
//       "title": "Chapter 031",
//       "volume": "04"
//     },
//     {
//       "chapter": "030",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/ee7c06d80a/04-030-0",
//       "lang": "en",
//       "title": "Chapter 030",
//       "volume": "04"
//     },
//     {
//       "chapter": "029",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/f72d7e2dc1/04-029-0",
//       "lang": "en",
//       "title": "Chapter 029",
//       "volume": "04"
//     },
//     {
//       "chapter": "028",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/8480baec98/04-028-0",
//       "lang": "en",
//       "title": "Chapter 028",
//       "volume": "04"
//     },
//     {
//       "chapter": "027",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/1c8d44ac80/04-027-0",
//       "lang": "en",
//       "title": "Chapter 027",
//       "volume": "04"
//     },
//     {
//       "chapter": "026",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/1c727d5e10/04-026-0",
//       "lang": "en",
//       "title": "Chapter 026",
//       "volume": "04"
//     },
//     {
//       "chapter": "025",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/613325aa92/04-025-0",
//       "lang": "en",
//       "title": "Chapter 025",
//       "volume": "04"
//     },
//     {
//       "chapter": "024",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/5866dd637a/03-024-0",
//       "lang": "en",
//       "title": "Chapter 024",
//       "volume": "03"
//     },
//     {
//       "chapter": "023",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/903fc9d582/03-023-0",
//       "lang": "en",
//       "title": "Chapter 023",
//       "volume": "03"
//     },
//     {
//       "chapter": "022",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/a2d5896458/03-022-0",
//       "lang": "en",
//       "title": "Chapter 022",
//       "volume": "03"
//     },
//     {
//       "chapter": "021",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/2a8a3f00aa/03-021-0",
//       "lang": "en",
//       "title": "Chapter 021",
//       "volume": "03"
//     },
//     {
//       "chapter": "020",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/5a3882d51c/03-020-0",
//       "lang": "en",
//       "title": "Chapter 020",
//       "volume": "03"
//     },
//     {
//       "chapter": "019",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/82ad52b0f3/03-019-0",
//       "lang": "en",
//       "title": "Chapter 019",
//       "volume": "03"
//     },
//     {
//       "chapter": "018",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/ad88f71a32/03-018-0",
//       "lang": "en",
//       "title": "Chapter 018",
//       "volume": "03"
//     },
//     {
//       "chapter": "017",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/f11cd55144/03-017-0",
//       "lang": "en",
//       "title": "Chapter 017",
//       "volume": "03"
//     },
//     {
//       "chapter": "016",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/84a7adeeb5/02-016-0",
//       "lang": "en",
//       "title": "Chapter 016",
//       "volume": "02"
//     },
//     {
//       "chapter": "015",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/3c9a498fef/02-015-0",
//       "lang": "en",
//       "title": "Chapter 015",
//       "volume": "02"
//     },
//     {
//       "chapter": "014",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/b5d96f2709/02-014-0",
//       "lang": "en",
//       "title": "Chapter 014",
//       "volume": "02"
//     },
//     {
//       "chapter": "013",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/8749c1c8bf/02-013-0",
//       "lang": "en",
//       "title": "Chapter 013",
//       "volume": "02"
//     },
//     {
//       "chapter": "012",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/4d120135a9/02-012-0",
//       "lang": "en",
//       "title": "Chapter 012",
//       "volume": "02"
//     },
//     {
//       "chapter": "011",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/bfd05ee850/02-011-0",
//       "lang": "en",
//       "title": "Chapter 011",
//       "volume": "02"
//     },
//     {
//       "chapter": "010",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/0b29f3da0f/02-010-0",
//       "lang": "en",
//       "title": "Chapter 010",
//       "volume": "02"
//     },
//     {
//       "chapter": "009",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/cdcf15405b/02-009-0",
//       "lang": "en",
//       "title": "Chapter 009",
//       "volume": "02"
//     },
//     {
//       "chapter": "008",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/23dbe2a110/01-008-0",
//       "lang": "en",
//       "title": "Chapter 008",
//       "volume": "01"
//     },
//     {
//       "chapter": "007",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/ebd8471eba/01-007-0",
//       "lang": "en",
//       "title": "Chapter 007",
//       "volume": "01"
//     },
//     {
//       "chapter": "006",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/a83bfa26f6/01-006-0",
//       "lang": "en",
//       "title": "Chapter 006",
//       "volume": "01"
//     },
//     {
//       "chapter": "005",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/d913b79cdc/01-005-0",
//       "lang": "en",
//       "title": "Chapter 005",
//       "volume": "01"
//     },
//     {
//       "chapter": "004",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/5a8fe4ddf7/01-004-0",
//       "lang": "en",
//       "title": "Chapter 004",
//       "volume": "01"
//     },
//     {
//       "chapter": "003",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/95aaad6650/01-003-0",
//       "lang": "en",
//       "title": "Chapter 003",
//       "volume": "01"
//     },
//     {
//       "chapter": "002",
//       "cover": "https://miacdn2.s3.fr-par.scw.cloud/fee44258dc/01-002-0",
//       "lang": "en",
//       "title": "Chapter 002",
//       "volume": "01"
//     },
//     {
//       "chapter": "001",
//       "cover": "https://miacdn.s3.fr-par.scw.cloud/5d4f8ded38/01-001-0",
//       "lang": "en",
//       "title": "Chapter 001",
//       "volume": "01"
//     }
//   ]
// }

export async function GetChapters () {
  return [
    {
      "chapter": "058",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/96656e07dc/09-058-0",
      "lang": "en",
      "title": "Chapter 058",
      "volume": "09"
    },
    {
      "chapter": "057",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/bca780e2d6/09-057-0",
      "lang": "en",
      "title": "Chapter 057",
      "volume": "09"
    },
    {
      "chapter": "056",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/44647b6998/09-056-0",
      "lang": "en",
      "title": "Chapter 056",
      "volume": "09"
    },
    {
      "chapter": "055",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/9fa28da704/09-055-0",
      "lang": "en",
      "title": "Chapter 055",
      "volume": "09"
    },
    {
      "chapter": "054",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/dc3d62f7e0/09-054-0",
      "lang": "en",
      "title": "Chapter 054",
      "volume": "09"
    },
    {
      "chapter": "053",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/c81ca27a5d/09-053-0",
      "lang": "en",
      "title": "Chapter 053",
      "volume": "09"
    },
    {
      "chapter": "052",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/a210a09ab1/09-052-0",
      "lang": "en",
      "title": "Chapter 052",
      "volume": "09"
    },
    {
      "chapter": "051x1",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/20ae838f69/08-051x1-0",
      "lang": "en",
      "title": "Chapter 051x1",
      "volume": "08"
    },
    {
      "chapter": "051",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/48a6205e26/09-051-0",
      "lang": "en",
      "title": "Chapter 051",
      "volume": "09"
    },
    {
      "chapter": "050",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/2fb59df1a0/08-050-0",
      "lang": "en",
      "title": "Chapter 050",
      "volume": "08"
    },
    {
      "chapter": "049",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/ad9327c0d9/08-049-0",
      "lang": "en",
      "title": "Chapter 049",
      "volume": "08"
    },
    {
      "chapter": "048",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/e6dd7d58ea/08-048-0",
      "lang": "en",
      "title": "Chapter 048",
      "volume": "08"
    },
    {
      "chapter": "047",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/9adddcb872/07-047-0",
      "lang": "en",
      "title": "Chapter 047",
      "volume": "07"
    },
    {
      "chapter": "046",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/fa42dd7eaa/07-046-0",
      "lang": "en",
      "title": "Chapter 046",
      "volume": "07"
    },
    {
      "chapter": "045",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/08b9870a00/07-045-0",
      "lang": "en",
      "title": "Chapter 045",
      "volume": "07"
    },
    {
      "chapter": "044",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/4c13ba4d44/07-044-0",
      "lang": "en",
      "title": "Chapter 044",
      "volume": "07"
    },
    {
      "chapter": "043",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/997001308d/07-043-0",
      "lang": "en",
      "title": "Chapter 043",
      "volume": "07"
    },
    {
      "chapter": "042x3",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/ceb7eeeb46/06-042x3-0",
      "lang": "en",
      "title": "Chapter 042x3",
      "volume": "06"
    },
    {
      "chapter": "042x1",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/f212ed5548/06-042x1-0",
      "lang": "en",
      "title": "Chapter 042x1",
      "volume": "06"
    },
    {
      "chapter": "042",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/7d94c98c4b/06-042-0",
      "lang": "en",
      "title": "Chapter 042",
      "volume": "06"
    },
    {
      "chapter": "041",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/3b038a2881/06-041-0",
      "lang": "en",
      "title": "Chapter 041",
      "volume": "06"
    },
    {
      "chapter": "040",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/03ed457a29/06-040-0",
      "lang": "en",
      "title": "Chapter 040",
      "volume": "06"
    },
    {
      "chapter": "039",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/8584b498f8/06-039-0",
      "lang": "en",
      "title": "Chapter 039",
      "volume": "06"
    },
    {
      "chapter": "038x1",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/575cd85dcc/06-038x1-0",
      "lang": "en",
      "title": "Chapter 038x1",
      "volume": "06"
    },
    {
      "chapter": "038",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/67793ccd12/05-038-0",
      "lang": "en",
      "title": "Chapter 038",
      "volume": "05"
    },
    {
      "chapter": "037",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/437f1191b0/05-037-0",
      "lang": "en",
      "title": "Chapter 037",
      "volume": "05"
    },
    {
      "chapter": "036",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/24f826de26/05-036-0",
      "lang": "en",
      "title": "Chapter 036",
      "volume": "05"
    },
    {
      "chapter": "035",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/27102ce88d/05-035-0",
      "lang": "en",
      "title": "Chapter 035",
      "volume": "05"
    },
    {
      "chapter": "034",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/4a526092a2/05-034-0",
      "lang": "en",
      "title": "Chapter 034",
      "volume": "05"
    },
    {
      "chapter": "033",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/39be98d80b/05-033-0",
      "lang": "en",
      "title": "Chapter 033",
      "volume": "05"
    },
    {
      "chapter": "032",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/5a456ae232/04-032-0",
      "lang": "en",
      "title": "Chapter 032",
      "volume": "04"
    },
    {
      "chapter": "031",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/3c1ce14fa7/04-031-0",
      "lang": "en",
      "title": "Chapter 031",
      "volume": "04"
    },
    {
      "chapter": "030",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/ee7c06d80a/04-030-0",
      "lang": "en",
      "title": "Chapter 030",
      "volume": "04"
    },
    {
      "chapter": "029",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/f72d7e2dc1/04-029-0",
      "lang": "en",
      "title": "Chapter 029",
      "volume": "04"
    },
    {
      "chapter": "028",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/8480baec98/04-028-0",
      "lang": "en",
      "title": "Chapter 028",
      "volume": "04"
    },
    {
      "chapter": "027",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/1c8d44ac80/04-027-0",
      "lang": "en",
      "title": "Chapter 027",
      "volume": "04"
    },
    {
      "chapter": "026",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/1c727d5e10/04-026-0",
      "lang": "en",
      "title": "Chapter 026",
      "volume": "04"
    },
    {
      "chapter": "025",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/613325aa92/04-025-0",
      "lang": "en",
      "title": "Chapter 025",
      "volume": "04"
    },
    {
      "chapter": "024",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/5866dd637a/03-024-0",
      "lang": "en",
      "title": "Chapter 024",
      "volume": "03"
    },
    {
      "chapter": "023",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/903fc9d582/03-023-0",
      "lang": "en",
      "title": "Chapter 023",
      "volume": "03"
    },
    {
      "chapter": "022",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/a2d5896458/03-022-0",
      "lang": "en",
      "title": "Chapter 022",
      "volume": "03"
    },
    {
      "chapter": "021",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/2a8a3f00aa/03-021-0",
      "lang": "en",
      "title": "Chapter 021",
      "volume": "03"
    },
    {
      "chapter": "020",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/5a3882d51c/03-020-0",
      "lang": "en",
      "title": "Chapter 020",
      "volume": "03"
    },
    {
      "chapter": "019",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/82ad52b0f3/03-019-0",
      "lang": "en",
      "title": "Chapter 019",
      "volume": "03"
    },
    {
      "chapter": "018",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/ad88f71a32/03-018-0",
      "lang": "en",
      "title": "Chapter 018",
      "volume": "03"
    },
    {
      "chapter": "017",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/f11cd55144/03-017-0",
      "lang": "en",
      "title": "Chapter 017",
      "volume": "03"
    },
    {
      "chapter": "016",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/84a7adeeb5/02-016-0",
      "lang": "en",
      "title": "Chapter 016",
      "volume": "02"
    },
    {
      "chapter": "015",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/3c9a498fef/02-015-0",
      "lang": "en",
      "title": "Chapter 015",
      "volume": "02"
    },
    {
      "chapter": "014",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/b5d96f2709/02-014-0",
      "lang": "en",
      "title": "Chapter 014",
      "volume": "02"
    },
    {
      "chapter": "013",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/8749c1c8bf/02-013-0",
      "lang": "en",
      "title": "Chapter 013",
      "volume": "02"
    },
    {
      "chapter": "012",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/4d120135a9/02-012-0",
      "lang": "en",
      "title": "Chapter 012",
      "volume": "02"
    },
    {
      "chapter": "011",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/bfd05ee850/02-011-0",
      "lang": "en",
      "title": "Chapter 011",
      "volume": "02"
    },
    {
      "chapter": "010",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/0b29f3da0f/02-010-0",
      "lang": "en",
      "title": "Chapter 010",
      "volume": "02"
    },
    {
      "chapter": "009",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/cdcf15405b/02-009-0",
      "lang": "en",
      "title": "Chapter 009",
      "volume": "02"
    },
    {
      "chapter": "008",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/23dbe2a110/01-008-0",
      "lang": "en",
      "title": "Chapter 008",
      "volume": "01"
    },
    {
      "chapter": "007",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/ebd8471eba/01-007-0",
      "lang": "en",
      "title": "Chapter 007",
      "volume": "01"
    },
    {
      "chapter": "006",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/a83bfa26f6/01-006-0",
      "lang": "en",
      "title": "Chapter 006",
      "volume": "01"
    },
    {
      "chapter": "005",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/d913b79cdc/01-005-0",
      "lang": "en",
      "title": "Chapter 005",
      "volume": "01"
    },
    {
      "chapter": "004",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/5a8fe4ddf7/01-004-0",
      "lang": "en",
      "title": "Chapter 004",
      "volume": "01"
    },
    {
      "chapter": "003",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/95aaad6650/01-003-0",
      "lang": "en",
      "title": "Chapter 003",
      "volume": "01"
    },
    {
      "chapter": "002",
      "cover": "https://miacdn2.s3.fr-par.scw.cloud/fee44258dc/01-002-0",
      "lang": "en",
      "title": "Chapter 002",
      "volume": "01"
    },
    {
      "chapter": "001",
      "cover": "https://miacdn.s3.fr-par.scw.cloud/5d4f8ded38/01-001-0",
      "lang": "en",
      "title": "Chapter 001",
      "volume": "01"
    }
  ]
}