
export const English = "en"
export const Japanese = "jp"

export function getLocale() {
  return localStorage.getItem("locale") || English
}

export function isEnglish() {
  return getLocale() === English
}

export function isJapanese() {
  return getLocale() === Japanese
}

export function Locale({en, jp}) {
  return getLocale() === English ? en : jp || en
}