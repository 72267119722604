import React from "react"

export function Spacer({l='0', r='0', t='0', b='0', children}) {
  const style = {
    "paddingLeft": l || 0,
    "paddingRight": r || 0,
    "paddingTop": t || 0,
    "paddingBottom": b || 0,
  }

  return (
    <div style={style}>
      {children}
    </div>
  )
}

export function preload_images(links) {
  return links.map((src) => {
    // console.log("preloading", src)
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve(src)
      }
      img.onerror = () => {
        reject(src)
      }
      img.src = src
    })
  })

}