import {Spacer} from "./utils/Spacer";
import {Image} from "react-bootstrap";
import {getLocale, Locale} from "./locale/localization";

export function AppHeader() {

  const toggleLanguage = () => {
    let lang = getLocale()
    localStorage.setItem("locale", lang === "en" ? "jp" : "en")
    window.location.reload()
  }

  return (
    <header className={"header"}>
      <div className={"flex-row"}>
        <Spacer l={".5em"} r={"1em"}>
          <Image src={"/tnktk-outline-small-2.png"}/>
        </Spacer>
        <Spacer r={"1em"}>
          <div className="slidingVertical type-head">
            <a href={"/"} className={"header-item title main"}><Locale en={`${window.document.title}`}
                                                                       jp={"メイドインアビ.net"}/></a>
            <a href={"/"} className={"header-item title sub"}><Locale en={"MIA.net"} jp={"MIA.net"}/></a>
          </div>
        </Spacer>
      </div>
      <div className={"flex-row"}>
        <Spacer l={".5em"} r={".5em"}>
          <a href={"/chapters/feed.rss"} className={"header-sub-item"}>
            <i className="bi bi-rss"/>&nbsp;<Locale en={"RSS"} jp={"RSS"}/>
          </a>
        </Spacer>
        <Spacer l={".5em"} r={".5em"}>
          <a href={"#"} onClick={toggleLanguage} className={"header-sub-item"}>
            <i className="bi bi-translate"/>&nbsp;<Locale en={"日本語"} jp={"English"}/>
          </a>
        </Spacer>
        {/* <Spacer l={".5em"} r={".5em"}>
          <a href={"https://noku.pw"} className={"header-sub-item"}>
            <i className="bi bi-globe2"/>&nbsp;<Locale en={"noku.pw"}/>
          </a>
        </Spacer> */}
        <Spacer l={".5em"} r={"1.5em"}>
          <a href={"https://chat.madeinabyss.net/"} className={"header-sub-item"}>
            <i className="bi bi-discord"/>&nbsp;<Locale en={"Discord"} jp={"ディスコード"}/>
          </a>
        </Spacer>
      </div>
    </header>
  )
}
