import './home.scss';
import {
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {useEffect, useState} from "react";
import {preload_images, Spacer} from "../utils/Spacer";
import {GetAllChapters} from "../utils/mock";
import {AppHeader} from "../header";
import {getLocale, isEnglish, Locale} from "../locale/localization";
import React from 'react';

function loadValue(key) {
  let settings = localStorage.getItem(key)
  if (!settings) {
    return
  }
  return JSON.parse(settings)
}

function compare( a, b ) {
  if ( a.chapter < b.chapter ){
    return 1;
  }
  if ( a.chapter > b.chapter ){
    return -1;
  }
  return 0;
}


export function Home() {
  const [checkpoint, setCheckpoint] = useState(loadValue("checkpoint") || 0);
  const [showLang, setShowLang] = useState(isEnglish() ? "en" : "ja");
  const [chapters, setChapters] = useState([]);
  useEffect(() => {
    GetAllChapters().then(c => {
      c.sort(compare)
      setChapters(c)
    })
  }, []);

  const toggleChapterLang = () => {
    setShowLang(showLang === "en" ? "ja" : "en")
  }

  return (
    <>
      <div className={"orange top-header"}>
        <Spacer t={"2em"} b={"1em"} l={"2em"} r={"2em"}>
          <AppHeader/>
        </Spacer>
        <div className={"header-container"}>
          <div className={"center"}>
            <h1>
              <Locale en={"THE ABYSS"} jp={"アビス"}/>
            </h1>
            <p>
              <Locale en={`The Abyss—a gaping chasm stretching down into the depths of the earth, filled with mysterious creatures
              and relics from a time long past. How did it come to be? What lies at the bottom? Countless brave
              individuals, known as Divers, have sought to solve these mysteries of the Abyss, fearlessly descending
              into its darkest realms. The best and bravest of the Divers, the White Whistles, are hailed as legends by
              those who remain on the surface.`} jp={`謎の生物や過去の遺物で埋め尽くされた、地底にぽっかりと開いた裂け目「アビス」。どのようにして作られたのか？底には何があるのか？これらの謎を解き明かすために、無数の勇敢なダイバーたちが、恐る恐る深淵の世界に降り立った。その中でも最も優秀で勇敢なダイバー「ホワイト・ホイッスル」は、地上に残った人々から伝説として称えられている。`}/>
            </p>
            <Spacer t={"3em"}/>
            <div style={{display: 'flex'}}>
              {
                !!chapters.length &&
                  <a href={`/chapters/${chapters[chapters.length - (1 + checkpoint)].chapter}#viewer`}>
                    <Button variant="outline-light dela">
                      <Locale en={`Read ${chapters[chapters.length - (1 + checkpoint)].title}`}
                              jp={`${chapters[chapters.length - (1 + checkpoint)].title}を読む`}/>
                    </Button>
                  </a>
              }
              <Spacer l={".5em"} r={"1.5em"}>
                <a href={"https://chat.madeinabyss.net/"}>
                  <Button variant="outline-light dela">
                    <i className="bi bi-discord"/>&nbsp;<Locale en={"Join Discord"} jp={"ディスコード"}/>
                  </Button>
                </a>
              </Spacer>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Spacer t={"2em"}/>
        <div className={"chapter-header"}>
          <h1>
            <Locale en={"Chapters"} jp={"チャプター"}/>
          </h1>
          <Button variant={"outline-light"} onClick={toggleChapterLang} className={"header-sub-item"}>
            <i className="bi bi-translate"/>&nbsp;{
            showLang === "en" ? <Locale en={"英語版エピソード"} jp={"English Chapters"}/>:
              <Locale en={"English Chapters"} jp={"英語版エピソード"}/>
          }
          </Button>
        </div>
        <Row>
          {
            chapters.filter(v => v.language === showLang).map(chapter =>
              <Col xs={6} sm={4} xl={3}>
                <Spacer t={".5em"} b={".5em"}>
                  <a href={`/chapters/${chapter.language}_${chapter.volume}_${chapter.chapter}.html#viewer`}>
                    <div className={"ch-card"}>
                      <div className={"segment-top"}>
                        <img src={chapter.thumbnail.link}/>
                      </div>
                      <div className={"segment-bottom noto"}>
                        <span>{chapter.volume} | {chapter.chapter}</span>
                        <span>{chapter.title}</span>
                      </div>
                    </div>
                  </a>
                </Spacer>
              </Col>
            )
          }
        </Row>
      </Container>
    </>
  )
}
