import './App.scss';
import './header.scss';
import './utils/animate.scss';
import {Viewer} from "./viewer/Viewer";
import {Home} from "./home/Home";
import {AppHeader} from "./header";
import './header.scss';
import {Locale} from "./locale/localization";
import React from 'react';

function Footer() {

  return <div className={"footer"}>
    <div className={"left"}>
      <a href={"https://noku.pw/"}>
        <Locale en={"noku.pw"} jp={"noku.pw"}/>
      </a>

    </div>
    <div>
      <Locale en={"©Akihito Tsukushi, Takeshobo/Made in Abyss \"Golden Land of Furious Suns\" Production Committee"}
        jp={"©つくしあきひと・竹書房／メイドインアビス「烈日の黄金郷」製作委員会"}/>
        &nbsp;|&nbsp;
        <span>Build: {window.sitebuild || "dev mode"} Original logo created by @nijiiro_table</span>
      </div>
  </div>
}


function App() {

  if (!window.location.hash.includes("viewer")) {
    return (
      <div className={"app"}>
        <Home/>
        <Footer/>
      </div>
    )
  }

  const meta_info = JSON.parse(atob(window.meta64))
  return (
    <div className={"app"}>
      <AppHeader/>
      <Viewer meta={meta_info}/>
      <Footer/>
    </div>
  );
}


export default App;
